//
// Navbar styles
//

.navbar {
  --#{$prefix}navbar-box-shadow: #{$navbar-box-shadow};
  --#{$prefix}navbar-padding-top: #{$navbar-padding-top};
  --#{$prefix}navbar-brand-img-margin-right: #{$navbar-brand-img-margin-right};

  box-shadow: var(--#{$prefix}navbar-box-shadow);
  padding-top: var(--#{$prefix}navbar-padding-top);
}

.navbar-toggler {
  border: 0;

  &:focus {
    box-shadow: none;
  }
}

.navbar-dark .navbar-toggler,
.navbar-light .navbar-toggler {
  border: 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
  img {
    margin-right: var(--#{$prefix}navbar-brand-img-margin-right);
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

// Navbar themes
.navbar-light {
  .navbar-toggler-icon {
    background-image: none;
  }
}

.navbar-dark {
  .navbar-toggler-icon {
    background-image: none;
  }
}

.navbar-dark,
.navbar[data-mdb-theme='dark'] {
  // scss-docs-start navbar-dark-css-vars
  --#{$prefix}navbar-color: #{$navbar-dark-color};
  --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
  --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
  --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
  --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
  --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};
  --#{$prefix}navbar-toggler-border-color: #{$navbar-dark-toggler-border-color};
  --#{$prefix}navbar-toggler-icon-bg: #{escape-svg($navbar-dark-toggler-icon-bg)};
  // scss-docs-end navbar-dark-css-vars
}
