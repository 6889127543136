// Your custom variables
// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
) !default;
// scss-docs-end container-max-widths

$list-group-item-padding-x: 0.5rem !default;

$variable-prefix: mdb- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

$font-family-roboto: "Manrope", sans-serif !default;

$font-family-sans-serif: system-ui, -apple-system, 'Segoe UI', "Manrope", Roboto, 'Helvetica Neue', 'Noto Sans',
'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji' !default;

$font-family-base: var(--#{$prefix}font-sans-serif) !default;

$font-weight-normal: 400 !default;

$body-color: #000000;