$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  }

  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline + 0px, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, calc($value / $rem-baseline) + 0rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

// Colors
$primary-color: #175fb9;
$primary-color-lighten: #3876c5;
$primary-color-light: #90b8ea;

$black: #282828;
$black-lighten: #595959;

$black-burger: #2a2a2a;

$red: #ee193d;
$exclusive: #d829ab;

$green: #6ec032;

$white: #fff;
$white-darken: #fafafa;

$gray: #9b9b9b;
$gray-darken: #707070;

$orange: #feae01;

$green-title: #6cbf31;

$fz-xs: rem(24px);
$fz-sm: rem(28px);

$border-color: #eaeaea;

// Box shadow
$box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05);

// Border radius
$border-radius: rem(5px);
$border-radius-2x: 10px;

// Transition
$easing: cubic-bezier(0.58, 0.01, 0.41, 1);
$transition: all 0.3s $easing;

// Text shadow
$text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);

.section--catalog {
  position: sticky;
  height: 100%;
  .catalogMenu--main {
    margin: 0 rem(-24px);
    position: relative;
    height: auto;
    .title{
      padding-left: rem(24px);
      font-size: 0.9rem;
    }
    .menu {
      padding-left: rem(24px);
      padding-right: rem(24px);
      a{
        margin-bottom: 10px;
        text-transform: none;
        color: #444444;
        &.active{
          color: #ffffff;
        }
      }
      .menu__link {
        display: block;
        padding: rem(15px) 0 rem(10px);
        &.is-active {
          color: $gray-darken;
          pointer-events: none;
        }
      }
    }
  }

  .offers {
    margin: 0 rem(-24px);
    margin-top: rem(53px);
    margin-bottom: rem(-32px);
  }

}

@media (max-width: 1140px) {
  .section--catalog {
    position: fixed;
    height: auto;
    transition: all .5s ease;

    &.out {
      transform: translateY(-100%);
    }
    .catalogNav{
      box-shadow: 0 0px 3px 0 rgba(var(--mdb-box-shadow-color-rgb), 0.16), 0 2px 2px 0 rgba(var(--mdb-box-shadow-color-rgb), 0.1);
      .catalogMenu--main {
        overflow: hidden;
        height: 75px;
        .title {
          padding-left: 0px;
          font-size: 0.7rem;
        }

        .catalogMenu__wrapper {
          overflow-x: auto;
        }

        .menu {
          .menu__item {
            &:not(:first-child) {
              margin-left: rem(16px);
            }
          }
        }
      }
    }
  }
  #homeProducts{
    padding-top: 160px;
  }
}


.btn-tag {
  background-color: var(--mdb-tertiary-bg) !important;
  text-transform: none !important;
  margin-bottom: 10px;
  box-shadow: none;
}

.btn-tag:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25), 0 3px 10px 5px rgba(0, 0, 0, 0.05) !important;
}


/* мменю в категории */
$sidenav-transform: translateX(-100%) !default;
$sidenav-background-color: $white !default;
$sidenav-width: 240px !default;
$sidenav-height: 100vh !default;
$sidenav-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05) !default;

$sidenav-data-hidden-false-transform: translateX(0%) !default;
$sidenav-data-color-light-color: rgba(255, 255, 255, 0.6) !default;
$sidenav-data-right-true-transform: translateX(100%) !default;
$sidenav-data-slim-collapsed-true-width: 77px !default;

$sidenav-menu-padding: 0.2rem !default;

$sidenav-collapse-sidenav-link-font-size: 0.85rem !default;
$sidenav-collapse-sidenav-link-height: 1.5rem !default;
$sidenav-collapse-sidenav-link-padding-left: 3rem !default;

$sidenav-link-font-size: 0.95rem !default !default;
$sidenav-link-padding-y: 1rem !default;
$sidenav-link-padding-x: 1.5rem !default;
$sidenav-link-height: 3rem !default;
$sidenav-link-border-radius: 5px !default;
$sidenav-link-transition: all 0.3s linear !default;

$sidenav-link-hover-color: #0d6efd !default;
$sidenav-link-hover-background-color: #f6f9fc !default;

$sidenav-link-active-focus-color: $sidenav-link-hover-color !default;
$sidenav-link-active-focus-background-color: $sidenav-link-hover-background-color !default;
$sidenav-link-active-color: $sidenav-link-hover-color !default;

$sidenav-rotate-icon-margin-right: 0.8rem !default;
$sidenav-rotate-icon-transition: transform 0.3s !default;

$sidenav-backdrop-background-color: rgba(0, 0, 0, 0.1) !default;

$sidenav-light-color: $sidenav-data-color-light-color !default;

$sidenav-subheading-font-size: 0.6rem !default;
$sidenav-subheading-padding-y: 1rem !default;
$sidenav-subheading-padding-x: 1.5rem !default;
$sidenav-subheading-fw: 700 !default;

$sidenav-sm-link-pt: 0.4rem !default;
$sidenav-sm-link-pb: $sidenav-sm-link-pt !default;

$sidenav-zindex: 1035 !default;
$sidenav-backdrop-zindex: 1034 !default;

.sidenav {
  // scss-docs-start sidenav-css-vars
  --#{$prefix}sidenav-transform: #{$sidenav-transform};
  --#{$prefix}sidenav-zindex: #{$sidenav-zindex};
  --#{$prefix}sidenav-background-color: #{$sidenav-background-color};
  --#{$prefix}sidenav-width: #{$sidenav-width};
  --#{$prefix}sidenav-height: #{$sidenav-height};
  --#{$prefix}sidenav-box-shadow: #{$sidenav-box-shadow};
  --#{$prefix}sidenav-data-hidden-false-transform: #{$sidenav-data-hidden-false-transform};
  --#{$prefix}sidenav-data-color-light-color: #{$sidenav-data-color-light-color};
  --#{$prefix}sidenav-data-right-true-transform: #{$sidenav-data-right-true-transform};
  --#{$prefix}sidenav-data-slim-collapsed-true-width: #{$sidenav-data-slim-collapsed-true-width};
  --#{$prefix}sidenav-menu-padding: #{$sidenav-menu-padding};
  --#{$prefix}sidenav-collapse-sidenav-link-font-size: #{$sidenav-collapse-sidenav-link-font-size};
  --#{$prefix}sidenav-collapse-sidenav-link-height: #{$sidenav-collapse-sidenav-link-height};
  --#{$prefix}sidenav-link-font-size: #{$sidenav-link-font-size};
  --#{$prefix}sidenav-link-padding-y: #{$sidenav-link-padding-y};
  --#{$prefix}sidenav-link-padding-x: #{$sidenav-link-padding-x};
  --#{$prefix}sidenav-link-height: #{$sidenav-link-height};
  --#{$prefix}sidenav-link-border-radius: #{$sidenav-link-border-radius};
  --#{$prefix}sidenav-link-transition: #{$sidenav-link-transition};
  --#{$prefix}sidenav-link-hover-background-color: #{$sidenav-link-hover-background-color};
  --#{$prefix}sidenav-link-active-focus-background-color: #{$sidenav-link-active-focus-background-color};
  --#{$prefix}sidenav-link-active-color: #{$sidenav-link-active-color};
  --#{$prefix}sidenav-subheading-font-size: #{$sidenav-subheading-font-size};
  --#{$prefix}sidenav-subheading-padding-y: #{$sidenav-subheading-padding-y};
  --#{$prefix}sidenav-subheading-padding-x: #{$sidenav-subheading-padding-x};
  --#{$prefix}sidenav-subheading-fw: #{$sidenav-subheading-fw};
  --#{$prefix}sidenav-sm-link-pt: #{$sidenav-sm-link-pt};
  --#{$prefix}sidenav-sm-link-pb: #{$sidenav-sm-link-pb};
  --#{$prefix}sidenav-rotate-icon-margin-right: #{$sidenav-rotate-icon-margin-right};
  --#{$prefix}sidenav-rotate-icon-transition: #{$sidenav-rotate-icon-transition};
  --#{$prefix}sidenav-light-color: #{$sidenav-light-color};
  // scss-docs-end sidenav-css-vars

  &[data-mdb-position='relative'] {
    position: relative;
    height: 100%;
  }
}

.sidenav-menu,
.sidenav-collapse {
  list-style: none;
  position: relative;
  padding: 0 var(--#{$prefix}sidenav-menu-padding);
  margin: 0;
}

.sidenav-collapse {
  display: none;
  padding: 0;

  &.show,
  &.collapsing {
    display: block;
  }

  .sidenav-link {
    font-size: var(--#{$prefix}sidenav-collapse-sidenav-link-font-size);
    height: var(--#{$prefix}sidenav-collapse-sidenav-link-height);
    padding-left: 2.4rem;
  }
}

.sidenav-item {
  position: relative;
}

.sidenav-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--#{$prefix}sidenav-link-font-size);
  padding: var(--#{$prefix}sidenav-link-padding-y) var(--#{$prefix}sidenav-link-padding-x);
  height: var(--#{$prefix}sidenav-link-height);
  color: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--#{$prefix}sidenav-link-border-radius);
  transition: var(--#{$prefix}sidenav-link-transition);

  &:hover {
    color: inherit;
    background-color: var(--#{$prefix}sidenav-link-hover-background-color);
    outline: none;
  }

  &:active,
  &:focus {
    color: inherit;
    background-color: var(--#{$prefix}sidenav-link-active-focus-background-color);
    outline: none;
  }

  &.active {
    color: var(--#{$prefix}sidenav-link-active-color);
  }

  i {
    color: #9fa6b2;
  }
}

.sidenav-subheading {
  color: unset;
  text-transform: uppercase;
  font-size: var(--#{$prefix}sidenav-subheading-font-size);
  padding: var(--#{$prefix}sidenav-subheading-padding-y)
  var(--#{$prefix}sidenav-subheading-padding-x);
  font-weight: var(--#{$prefix}sidenav-subheading-fw);
}

.sidenav-sm {
  .sidenav-link {
    padding-top: var(--#{$prefix}sidenav-sm-link-pt);
    padding-bottom: var(--#{$prefix}sidenav-sm-link-pb);
    height: initial;
  }
}

.rotate-icon {
  position: absolute;
  right: 0;
  margin-left: auto;
  margin-right: var(--#{$prefix}sidenav-rotate-icon-margin-right);
  transition: var(--#{$prefix}sidenav-rotate-icon-transition);
}

.sidenav-backdrop {
  --#{$prefix}sidenav-backdrop-zindex: #{$sidenav-backdrop-zindex};
  --#{$prefix}sidenav-backdrop-background-color: #{$sidenav-backdrop-background-color};

  z-index: var(--#{$prefix}sidenav-backdrop-zindex);
  top: 0;
  left: 0;
  background-color: var(--#{$prefix}sidenav-backdrop-background-color);
}

.sidenav-light {
  color: var(--#{$prefix}sidenav-light-color);
}

// Color options

/* конец меню в категории */

/* меню мобильное в подвале */
#menuBottomModal, #contactsBottomModal{
  --#{$prefix}sidenav-link-hover-background-color: #{$sidenav-link-hover-background-color};
  --#{$prefix}sidenav-link-active-focus-background-color: #{$sidenav-link-active-focus-background-color};
  --#{$prefix}sidenav-link-active-color: #{$sidenav-link-active-color};

  ul.nav {
    display: block;
    list-style: none;
    margin-left: -1rem;
    li.nav-li{
      a.nav-link {
        &{
          padding: 4px 0px 4px;
          font-size: 15px;
          padding-left: 1rem;
        }
        &:hover {
          color: inherit;
          background-color: var(--#{$prefix}sidenav-link-hover-background-color);
          outline: none;
        }
        &:active,
        &:focus {
          color: inherit;
          background-color: var(--#{$prefix}sidenav-link-active-focus-background-color);
          outline: none;
        }
        &.active {
          color: var(--#{$prefix}sidenav-link-active-color);
        }
      }
      ul.nav-ul{
        list-style: none;
        padding-left: 1rem;
        li.nav-ul-li{

          >a.nav-link{
            display: inline-block;
            font-size: 14px;
            margin-left: -0.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
          &::before {
            content: "•";
            color: #009C39;
            font-weight: bold;
            display: inline-block;
            width: 15px;
          }
        }
      }
    }
  }
}
/* конец меню мобильное в подвале*/