footer{
  ul.nav{
    li{
      a.nav-link{
        font-size: 0.9rem;
        color: #000000 !important;
      }
    }
  }
}

html{
  height:100%;
}
body {
  min-height:100%;
  margin:0;
  color: #000000;
}

.modal {
  touch-action: none; /* Отключить жесты прокрутки при открытой модалке */
}

.fs-7{
  font-size: 0.7rem;
}
.fs-8{
  font-size: 0.8rem;
}
.fs-9{
  font-size: 0.9rem;
}

/* сделаем таймпикер побольше */
.datetimepicker  td,
.datetimepicker th {
  width: 35px;
  height: 35px;
  padding: 8px !important;
}
/* добавим значок рубля после цены*/
.price:after {
  content: ' \20BD';
}

/* блюр фона для фоток */
.wrapper {
  background: white;
  position: relative;
  overflow: hidden;

  .image-bg {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .blur-bg {
    position: absolute;
    left: -25px;
    right: -25px;
    top: -25px;
    bottom: -25px;
    background-color: white;
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    filter: blur(15px);
    transform: scale(1.8);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
/**/
/* order */
.gradient-custom-2 {
  /* fallback for old browsers */
  background: #a1c4fd;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1))
}


.card-stepper {
  z-index: 0
}
.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))
}
/*endorder */



.botanic-logo{
  background-image: url('/theme/image/botanic-icon.svg');
  width:40px;
  height:40px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.botanic-logo-header{
  background-image: url('/theme/image/botanic-logo.svg');
  width:170px;
  height:55px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.tcvetochnica-logo{
  background-image: url('/theme/image/tcvetochnica-icon.svg');
  width:40px;
  height:40px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.tcvetochnica-logo-header{
  background-image: url('/theme/image/tcvetochnica-logo.svg');
  width:170px;
  height:55px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.buketlab-logo{
  background-image: url('/theme/image/buketlab-icon.svg');
  width:40px;
  height:40px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.buketlab-logo-header{
  background-image: url('/theme/image/buketlab-logo.svg');
  width:170px;
  height:55px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.fa-gradient {
  background: -webkit-gradient(linear, left top, left bottom, from(#f00), to(#333));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fa-telegram-color{
  background: #27a7e7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fa-phone-color{
  background: -webkit-gradient(linear, left top, left bottom, from(#5bff75), to(#06b624));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fa-vk-color{
  background: -webkit-gradient(linear, left top, left bottom, from(#7096c7), to(#243c4f));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fa-insta-color{
  background: -webkit-gradient(linear, left top, left bottom, from(#d23670), to(#eea810));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* костыль для модалок снизу чтоб не пееркрывали меню*/
.modal .modal-dialog.modal-bottom{
  bottom: 3.6rem !important;
}
#mobFooter{
  z-index: 1057;
  a{
    color: #555555 !important;
  }
}
/* конец костыль для модалок снизу чтоб не пееркрывали меню*/

.container-fluid small{
  color: #858585 !important;
  font-size: 0.8rem;
}

/* товар */

@media (max-width: 1140px) {
  #product{
    position: fixed;
    bottom: 55px;
    left: 12px;
    right: 12px;
    z-index: 1031;
  }
}
/* товар */