/*******************************
             Flag
*******************************/
#mdb-table-flag tr {
  cursor: pointer;
}
.mdb-flag-selected {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
  margin-top: 10px;
}
.mdb-selected-flag-text {
  margin: 0 auto;
  max-width: 150px;
}

i.flag:not(.icon) {
  display: inline-block;
  width: 16px;
  height: 11px;
  margin: 0 0.5em 0 0;
  line-height: 11px;
  text-decoration: inherit;
  vertical-align: baseline;
  backface-visibility: hidden;
}

/* Sprite */
i.flag {
  &::before {
    display: inline-block;
    width: 16px;
    height: 11px;
    content: '';
    background: url('https://mdbootstrap.com/img/svg/flags.png') no-repeat -108px -1976px;
  }

  /* Flag Sprite Based On http://www.famfamfam.com/lab/icons/flags/ */

  /*******************************
         Theme Overrides
*******************************/

  &-ad:before,
  &-andorra:before {
    background-position: 0 0 !important;
  }

  &-ae:before,
  &-united-arab-emirates:before,
  &-uae:before {
    background-position: 0 -26px !important;
  }
  &-af:before,
  &-afghanistan:before {
    background-position: 0 -52px !important;
  }
  &-ag:before,
  &-antigua:before {
    background-position: 0 -78px !important;
  }
  &-ai:before,
  &-anguilla:before {
    background-position: 0 -104px !important;
  }
  &-al:before,
  &-albania:before {
    background-position: 0 -130px !important;
  }
  &-am:before,
  &-armenia:before {
    background-position: 0 -156px !important;
  }
  &-an:before,
  &-netherlands-antilles:before {
    background-position: 0 -182px !important;
  }
  &-ao:before,
  &-angola:before {
    background-position: 0 -208px !important;
  }
  &-ar:before,
  &-argentina:before {
    background-position: 0 -234px !important;
  }
  &-as:before,
  &-american-samoa:before {
    background-position: 0 -260px !important;
  }
  &-at:before,
  &-austria:before {
    background-position: 0 -286px !important;
  }
  &-au:before,
  &-australia:before {
    background-position: 0 -312px !important;
  }
  &-aw:before,
  &-aruba:before {
    background-position: 0 -338px !important;
  }
  &-ax:before,
  &-aland-islands:before {
    background-position: 0 -364px !important;
  }
  &-az:before,
  &-azerbaijan:before {
    background-position: 0 -390px !important;
  }
  &-ba:before,
  &-bosnia:before {
    background-position: 0 -416px !important;
  }
  &-bb:before,
  &-barbados:before {
    background-position: 0 -442px !important;
  }
  &-bd:before,
  &-bangladesh:before {
    background-position: 0 -468px !important;
  }
  &-be:before,
  &-belgium:before {
    background-position: 0 -494px !important;
  }
  &-bf:before,
  &-burkina-faso:before {
    background-position: 0 -520px !important;
  }
  &-bg:before,
  &-bulgaria:before {
    background-position: 0 -546px !important;
  }
  &-bh:before,
  &-bahrain:before {
    background-position: 0 -572px !important;
  }
  &-bi:before,
  &-burundi:before {
    background-position: 0 -598px !important;
  }
  &-bj:before,
  &-benin:before {
    background-position: 0 -624px !important;
  }
  &-bm:before,
  &-bermuda:before {
    background-position: 0 -650px !important;
  }
  &-bn:before,
  &-brunei:before {
    background-position: 0 -676px !important;
  }
  &-bo:before,
  &-bolivia:before {
    background-position: 0 -702px !important;
  }
  &-br:before,
  &-brazil:before {
    background-position: 0 -728px !important;
  }
  &-bs:before,
  &-bahamas:before {
    background-position: 0 -754px !important;
  }
  &-bt:before,
  &-bhutan:before {
    background-position: 0 -780px !important;
  }
  &-bv:before,
  &-bouvet-island:before {
    background-position: 0 -806px !important;
  }
  &-bw:before,
  &-botswana:before {
    background-position: 0 -832px !important;
  }
  &-by:before,
  &-belarus:before {
    background-position: 0 -858px !important;
  }
  &-bz:before,
  &-belize:before {
    background-position: 0 -884px !important;
  }
  &-ca:before,
  &-canada:before {
    background-position: 0 -910px !important;
  }
  &-cc:before,
  &-cocos-islands:before {
    background-position: 0 -962px !important;
  }
  &-cd:before,
  &-congo:before {
    background-position: 0 -988px !important;
  }
  &-cf:before,
  &-central-african-republic:before {
    background-position: 0 -1014px !important;
  }
  &-cg:before,
  &-congo-brazzaville:before {
    background-position: 0 -1040px !important;
  }
  &-ch:before,
  &-switzerland:before {
    background-position: 0 -1066px !important;
  }
  &-ci:before,
  &-cote-divoire:before {
    background-position: 0 -1092px !important;
  }
  &-ck:before,
  &-cook-islands:before {
    background-position: 0 -1118px !important;
  }
  &-cl:before,
  &-chile:before {
    background-position: 0 -1144px !important;
  }
  &-cm:before,
  &-cameroon:before {
    background-position: 0 -1170px !important;
  }
  &-cn:before,
  &-china:before {
    background-position: 0 -1196px !important;
  }
  &-co:before,
  &-colombia:before {
    background-position: 0 -1222px !important;
  }
  &-cr:before,
  &-costa-rica:before {
    background-position: 0 -1248px !important;
  }
  &-cs:before,
  &-serbia:before {
    background-position: 0 -1274px !important;
  }
  &-cu:before,
  &-cuba:before {
    background-position: 0 -1300px !important;
  }
  &-cv:before,
  &-cape-verde:before {
    background-position: 0 -1326px !important;
  }
  &-cx:before,
  &-christmas-island:before {
    background-position: 0 -1352px !important;
  }
  &-cy:before,
  &-cyprus:before {
    background-position: 0 -1378px !important;
  }
  &-cz:before,
  &-czech-republic:before {
    background-position: 0 -1404px !important;
  }
  &-de:before,
  &-germany:before {
    background-position: 0 -1430px !important;
  }
  &-dj:before,
  &-djibouti:before {
    background-position: 0 -1456px !important;
  }
  &-dk:before,
  &-denmark:before {
    background-position: 0 -1482px !important;
  }
  &-dm:before,
  &-dominica:before {
    background-position: 0 -1508px !important;
  }
  &-do:before,
  &-dominican-republic:before {
    background-position: 0 -1534px !important;
  }
  &-dz:before,
  &-algeria:before {
    background-position: 0 -1560px !important;
  }
  &-ec:before,
  &-ecuador:before {
    background-position: 0 -1586px !important;
  }
  &-ee:before,
  &-estonia:before {
    background-position: 0 -1612px !important;
  }
  &-eg:before,
  &-egypt:before {
    background-position: 0 -1638px !important;
  }
  &-eh:before,
  &-western-sahara:before {
    background-position: 0 -1664px !important;
  }
  &-gb-eng:before,
  &-england:before {
    background-position: 0 -1690px !important;
  }
  &-er:before,
  &-eritrea:before {
    background-position: 0 -1716px !important;
  }
  &-es:before,
  &-spain:before {
    background-position: 0 -1742px !important;
  }
  &-et:before,
  &-ethiopia:before {
    background-position: 0 -1768px !important;
  }
  &-eu:before,
  &-european-union:before {
    background-position: 0 -1794px !important;
  }
  &-fi:before,
  &-finland:before {
    background-position: 0 -1846px !important;
  }
  &-fj:before,
  &-fiji:before {
    background-position: 0 -1872px !important;
  }
  &-fk:before,
  &-falkland-islands:before {
    background-position: 0 -1898px !important;
  }
  &-fm:before,
  &-micronesia:before {
    background-position: 0 -1924px !important;
  }
  &-fo:before,
  &-faroe-islands:before {
    background-position: 0 -1950px !important;
  }
  &-fr:before,
  &-france:before {
    background-position: 0 -1976px !important;
  }
  &-ga:before,
  &-gabon:before {
    background-position: -36px 0 !important;
  }
  &-gb:before,
  &-uk:before,
  &-united-kingdom:before {
    background-position: -36px -26px !important;
  }
  &-gd:before,
  &-grenada:before {
    background-position: -36px -52px !important;
  }
  &-ge:before,
  &-georgia:before {
    background-position: -36px -78px !important;
  }
  &-gf:before,
  &-french-guiana:before {
    background-position: -36px -104px !important;
  }
  &-gh:before,
  &-ghana:before {
    background-position: -36px -130px !important;
  }
  &-gi:before,
  &-gibraltar:before {
    background-position: -36px -156px !important;
  }
  &-gl:before,
  &-greenland:before {
    background-position: -36px -182px !important;
  }
  &-gm:before,
  &-gambia:before {
    background-position: -36px -208px !important;
  }
  &-gn:before,
  &-guinea:before {
    background-position: -36px -234px !important;
  }
  &-gp:before,
  &-guadeloupe:before {
    background-position: -36px -260px !important;
  }
  &-gq:before,
  &-equatorial-guinea:before {
    background-position: -36px -286px !important;
  }
  &-gr:before,
  &-greece:before {
    background-position: -36px -312px !important;
  }
  &-gs:before,
  &-sandwich-islands:before {
    background-position: -36px -338px !important;
  }
  &-gt:before,
  &-guatemala:before {
    background-position: -36px -364px !important;
  }
  &-gu:before,
  &-guam:before {
    background-position: -36px -390px !important;
  }
  &-gw:before,
  &-guinea-bissau:before {
    background-position: -36px -416px !important;
  }
  &-gy:before,
  &-guyana:before {
    background-position: -36px -442px !important;
  }
  &-hk:before,
  &-hong-kong:before {
    background-position: -36px -468px !important;
  }
  &-hm:before,
  &-heard-island:before {
    background-position: -36px -494px !important;
  }
  &-hn:before,
  &-honduras:before {
    background-position: -36px -520px !important;
  }
  &-hr:before,
  &-croatia:before {
    background-position: -36px -546px !important;
  }
  &-ht:before,
  &-haiti:before {
    background-position: -36px -572px !important;
  }
  &-hu:before,
  &-hungary:before {
    background-position: -36px -598px !important;
  }
  &-id:before,
  &-indonesia:before {
    background-position: -36px -624px !important;
  }
  &-ie:before,
  &-ireland:before {
    background-position: -36px -650px !important;
  }
  &-il:before,
  &-israel:before {
    background-position: -36px -676px !important;
  }
  &-in:before,
  &-india:before {
    background-position: -36px -702px !important;
  }
  &-io:before,
  &-indian-ocean-territory:before {
    background-position: -36px -728px !important;
  }
  &-iq:before,
  &-iraq:before {
    background-position: -36px -754px !important;
  }
  &-ir:before,
  &-iran:before {
    background-position: -36px -780px !important;
  }
  &-is:before,
  &-iceland:before {
    background-position: -36px -806px !important;
  }
  &-it:before,
  &-italy:before {
    background-position: -36px -832px !important;
  }
  &-jm:before,
  &-jamaica:before {
    background-position: -36px -858px !important;
  }
  &-jo:before,
  &-jordan:before {
    background-position: -36px -884px !important;
  }
  &-jp:before,
  &-japan:before {
    background-position: -36px -910px !important;
  }
  &-ke:before,
  &-kenya:before {
    background-position: -36px -936px !important;
  }
  &-kg:before,
  &-kyrgyzstan:before {
    background-position: -36px -962px !important;
  }
  &-kh:before,
  &-cambodia:before {
    background-position: -36px -988px !important;
  }
  &-ki:before,
  &-kiribati:before {
    background-position: -36px -1014px !important;
  }
  &-km:before,
  &-comoros:before {
    background-position: -36px -1040px !important;
  }
  &-kn:before,
  &-saint-kitts-and-nevis:before {
    background-position: -36px -1066px !important;
  }
  &-kp:before,
  &-north-korea:before {
    background-position: -36px -1092px !important;
  }
  &-kr:before,
  &-south-korea:before {
    background-position: -36px -1118px !important;
  }
  &-kw:before,
  &-kuwait:before {
    background-position: -36px -1144px !important;
  }
  &-ky:before,
  &-cayman-islands:before {
    background-position: -36px -1170px !important;
  }
  &-kz:before,
  &-kazakhstan:before {
    background-position: -36px -1196px !important;
  }
  &-la:before,
  &-laos:before {
    background-position: -36px -1222px !important;
  }
  &-lb:before,
  &-lebanon:before {
    background-position: -36px -1248px !important;
  }
  &-lc:before,
  &-saint-lucia:before {
    background-position: -36px -1274px !important;
  }
  &-li:before,
  &-liechtenstein:before {
    background-position: -36px -1300px !important;
  }
  &-lk:before,
  &-sri-lanka:before {
    background-position: -36px -1326px !important;
  }
  &-lr:before,
  &-liberia:before {
    background-position: -36px -1352px !important;
  }
  &-ls:before,
  &-lesotho:before {
    background-position: -36px -1378px !important;
  }
  &-lt:before,
  &-lithuania:before {
    background-position: -36px -1404px !important;
  }
  &-lu:before,
  &-luxembourg:before {
    background-position: -36px -1430px !important;
  }
  &-lv:before,
  &-latvia:before {
    background-position: -36px -1456px !important;
  }
  &-ly:before,
  &-libya:before {
    background-position: -36px -1482px !important;
  }
  &-ma:before,
  &-morocco:before {
    background-position: -36px -1508px !important;
  }
  &-mc:before,
  &-monaco:before {
    background-position: -36px -1534px !important;
  }
  &-md:before,
  &-moldova:before {
    background-position: -36px -1560px !important;
  }
  &-me:before,
  &-montenegro:before {
    background-position: -36px -1586px !important;
  }
  &-mg:before,
  &-madagascar:before {
    background-position: -36px -1613px !important;
  }
  &-mh:before,
  &-marshall-islands:before {
    background-position: -36px -1639px !important;
  }
  &-mk:before,
  &-macedonia:before {
    background-position: -36px -1665px !important;
  }
  &-ml:before,
  &-mali:before {
    background-position: -36px -1691px !important;
  }
  &-mm:before,
  &-myanmar:before,
  &-burma:before {
    background-position: -73px -1821px !important;
  }
  &-mn:before,
  &-mongolia:before {
    background-position: -36px -1743px !important;
  }
  &-mo:before,
  &-macau:before {
    background-position: -36px -1769px !important;
  }
  &-mp:before,
  &-northern-mariana-islands:before {
    background-position: -36px -1795px !important;
  }
  &-mq:before,
  &-martinique:before {
    background-position: -36px -1821px !important;
  }
  &-mr:before,
  &-mauritania:before {
    background-position: -36px -1847px !important;
  }
  &-ms:before,
  &-montserrat:before {
    background-position: -36px -1873px !important;
  }
  &-mt:before,
  &-malta:before {
    background-position: -36px -1899px !important;
  }
  &-mu:before,
  &-mauritius:before {
    background-position: -36px -1925px !important;
  }
  &-mv:before,
  &-maldives:before {
    background-position: -36px -1951px !important;
  }
  &-mw:before,
  &-malawi:before {
    background-position: -36px -1977px !important;
  }
  &-mx:before,
  &-mexico:before {
    background-position: -72px 0 !important;
  }
  &-my:before,
  &-malaysia:before {
    background-position: -72px -26px !important;
  }
  &-mz:before,
  &-mozambique:before {
    background-position: -72px -52px !important;
  }
  &-na:before,
  &-namibia:before {
    background-position: -72px -78px !important;
  }
  &-nc:before,
  &-new-caledonia:before {
    background-position: -72px -104px !important;
  }
  &-ne:before,
  &-niger:before {
    background-position: -72px -130px !important;
  }
  &-nf:before,
  &-norfolk-island:before {
    background-position: -72px -156px !important;
  }
  &-ng:before,
  &-nigeria:before {
    background-position: -72px -182px !important;
  }
  &-ni:before,
  &-nicaragua:before {
    background-position: -72px -208px !important;
  }
  &-nl:before,
  &-netherlands:before {
    background-position: -72px -234px !important;
  }
  &-no:before,
  &-norway:before {
    background-position: -72px -260px !important;
  }
  &-np:before,
  &-nepal:before {
    background-position: -72px -286px !important;
  }
  &-nr:before,
  &-nauru:before {
    background-position: -72px -312px !important;
  }
  &-nu:before,
  &-niue:before {
    background-position: -72px -338px !important;
  }
  &-nz:before,
  &-new-zealand:before {
    background-position: -72px -364px !important;
  }
  &-om:before,
  &-oman:before {
    background-position: -72px -390px !important;
  }
  &-pa:before,
  &-panama:before {
    background-position: -72px -416px !important;
  }
  &-pe:before,
  &-peru:before {
    background-position: -72px -442px !important;
  }
  &-pf:before,
  &-french-polynesia:before {
    background-position: -72px -468px !important;
  }
  &-pg:before,
  &-new-guinea:before {
    background-position: -72px -494px !important;
  }
  &-ph:before,
  &-philippines:before {
    background-position: -72px -520px !important;
  }
  &-pk:before,
  &-pakistan:before {
    background-position: -72px -546px !important;
  }
  &-pl:before,
  &-poland:before {
    background-position: -72px -572px !important;
  }
  &-pm:before,
  &-saint-pierre:before {
    background-position: -72px -598px !important;
  }
  &-pn:before,
  &-pitcairn-islands:before {
    background-position: -72px -624px !important;
  }
  &-pr:before,
  &-puerto-rico:before {
    background-position: -72px -650px !important;
  }
  &-ps:before,
  &-palestine:before {
    background-position: -72px -676px !important;
  }
  &-pt:before,
  &-portugal:before {
    background-position: -72px -702px !important;
  }
  &-pw:before,
  &-palau:before {
    background-position: -72px -728px !important;
  }
  &-py:before,
  &-paraguay:before {
    background-position: -72px -754px !important;
  }
  &-qa:before,
  &-qatar:before {
    background-position: -72px -780px !important;
  }
  &-re:before,
  &-reunion:before {
    background-position: -72px -806px !important;
  }
  &-ro:before,
  &-romania:before {
    background-position: -72px -832px !important;
  }
  &-rs:before,
  &-serbia:before {
    background-position: -72px -858px !important;
  }
  &-ru:before,
  &-russia:before {
    background-position: -72px -884px !important;
  }
  &-rw:before,
  &-rwanda:before {
    background-position: -72px -910px !important;
  }
  &-sa:before,
  &-saudi-arabia:before {
    background-position: -72px -936px !important;
  }
  &-sb:before,
  &-solomon-islands:before {
    background-position: -72px -962px !important;
  }
  &-sc:before,
  &-seychelles:before {
    background-position: -72px -988px !important;
  }
  &-gb-sct:before,
  &-scotland:before {
    background-position: -72px -1014px !important;
  }
  &-sd:before,
  &-sudan:before {
    background-position: -72px -1040px !important;
  }
  &-se:before,
  &-sweden:before {
    background-position: -72px -1066px !important;
  }
  &-sg:before,
  &-singapore:before {
    background-position: -72px -1092px !important;
  }
  &-sh:before,
  &-saint-helena:before {
    background-position: -72px -1118px !important;
  }
  &-si:before,
  &-slovenia:before {
    background-position: -72px -1144px !important;
  }
  &-sj:before,
  &-svalbard:before,
  &-jan-mayen:before {
    background-position: -72px -1170px !important;
  }
  &-sk:before,
  &-slovakia:before {
    background-position: -72px -1196px !important;
  }
  &-sl:before,
  &-sierra-leone:before {
    background-position: -72px -1222px !important;
  }
  &-sm:before,
  &-san-marino:before {
    background-position: -72px -1248px !important;
  }
  &-sn:before,
  &-senegal:before {
    background-position: -72px -1274px !important;
  }
  &-so:before,
  &-somalia:before {
    background-position: -72px -1300px !important;
  }
  &-sr:before,
  &-suriname:before {
    background-position: -72px -1326px !important;
  }
  &-st:before,
  &-sao-tome:before {
    background-position: -72px -1352px !important;
  }
  &-sv:before,
  &-el-salvador:before {
    background-position: -72px -1378px !important;
  }
  &-sy:before,
  &-syria:before {
    background-position: -72px -1404px !important;
  }
  &-sz:before,
  &-swaziland:before {
    background-position: -72px -1430px !important;
  }
  &-tc:before,
  &-caicos-islands:before {
    background-position: -72px -1456px !important;
  }
  &-td:before,
  &-chad:before {
    background-position: -72px -1482px !important;
  }
  &-tf:before,
  &-french-territories:before {
    background-position: -72px -1508px !important;
  }
  &-tg:before,
  &-togo:before {
    background-position: -72px -1534px !important;
  }
  &-th:before,
  &-thailand:before {
    background-position: -72px -1560px !important;
  }
  &-tj:before,
  &-tajikistan:before {
    background-position: -72px -1586px !important;
  }
  &-tk:before,
  &-tokelau:before {
    background-position: -72px -1612px !important;
  }
  &-tl:before,
  &-timorleste:before {
    background-position: -72px -1638px !important;
  }
  &-tm:before,
  &-turkmenistan:before {
    background-position: -72px -1664px !important;
  }
  &-tn:before,
  &-tunisia:before {
    background-position: -72px -1690px !important;
  }
  &-to:before,
  &-tonga:before {
    background-position: -72px -1716px !important;
  }
  &-tr:before,
  &-turkey:before {
    background-position: -72px -1742px !important;
  }
  &-tt:before,
  &-trinidad:before {
    background-position: -72px -1768px !important;
  }
  &-tv:before,
  &-tuvalu:before {
    background-position: -72px -1794px !important;
  }
  &-tw:before,
  &-taiwan:before {
    background-position: -72px -1820px !important;
  }
  &-tz:before,
  &-tanzania:before {
    background-position: -72px -1846px !important;
  }
  &-ua:before,
  &-ukraine:before {
    background-position: -72px -1872px !important;
  }
  &-ug:before,
  &-uganda:before {
    background-position: -72px -1898px !important;
  }
  &-um:before,
  &-us-minor-islands:before {
    background-position: -72px -1924px !important;
  }
  &-us:before,
  &-america:before,
  &-united-states:before {
    background-position: -72px -1950px !important;
  }
  &-uy:before,
  &-uruguay:before {
    background-position: -72px -1976px !important;
  }
  &-uz:before,
  &-uzbekistan:before {
    background-position: -108px 0 !important;
  }
  &-va:before,
  &-vatican-city:before {
    background-position: -108px -26px !important;
  }
  &-vc:before,
  &-saint-vincent:before {
    background-position: -108px -52px !important;
  }
  &-ve:before,
  &-venezuela:before {
    background-position: -108px -78px !important;
  }
  &-vg:before,
  &-british-virgin-islands:before {
    background-position: -108px -104px !important;
  }
  &-vi:before,
  &-us-virgin-islands:before {
    background-position: -108px -130px !important;
  }
  &-vn:before,
  &-vietnam:before {
    background-position: -108px -156px !important;
  }
  &-vu:before,
  &-vanuatu:before {
    background-position: -108px -182px !important;
  }
  &-gb-wls:before,
  &-wales:before {
    background-position: -108px -208px !important;
  }
  &-wf:before,
  &-wallis-and-futuna:before {
    background-position: -108px -234px !important;
  }
  &-ws:before,
  &-samoa:before {
    background-position: -108px -260px !important;
  }
  &-ye:before,
  &-yemen:before {
    background-position: -108px -286px !important;
  }
  &-yt:before,
  &-mayotte:before {
    background-position: -108px -312px !important;
  }
  &-za:before,
  &-south-africa:before {
    background-position: -108px -338px !important;
  }
  &-zm:before,
  &-zambia:before {
    background-position: -108px -364px !important;
  }
  &-zw:before,
  &-zimbabwe:before {
    background-position: -108px -390px !important;
  }
}
/*******************************
         Site Overrides
*******************************/
