//
// Badge styles
//
// Additional badge options tailored to the MDB style

// A "dot" badge, which is useful, for example, for icons
.badge-dot {
  --#{$prefix}badge-border-radius: #{$badge-dot-border-radius};
  --#{$prefix}badge-height: #{$badge-dot-height};
  --#{$prefix}badge-width: #{$badge-dot-width};
  --#{$prefix}badge-margin-left: #{$badge-dot-margin-left};

  position: absolute;
  min-width: 0;
  width: var(--#{$prefix}badge-width);
  height: var(--#{$prefix}badge-height);
  border-radius: var(--#{$prefix}badge-border-radius);
  padding: 0;
  margin-left: var(--#{$prefix}badge-margin-left);

  &:empty {
    display: inline-block;
  }
}

// "Notification" badge that shows the number of notifications, e.g. the number of unread messages
.badge-notification {
  --#{$prefix}badge-font-size: #{$badge-notification-font-size};
  --#{$prefix}badge-padding-x: #{$badge-notification-padding-x};
  --#{$prefix}badge-padding-y: #{$badge-notification-padding-y};
  --#{$prefix}badge-margin-top: #{$badge-notification-margin-top};
  --#{$prefix}badge-margin-left: #{$badge-notification-margin-left};

  position: absolute;
  font-size: var(--#{$prefix}badge-font-size);
  padding: var(--#{$prefix}badge-padding-y) var(--#{$prefix}badge-padding-x);
  margin-top: var(--#{$prefix}badge-margin-top);
  margin-left: var(--#{$prefix}badge-margin-left);
}

// Contextual colors options
@each $color, $value in $theme-colors {
  .badge-#{$color} {
    background-color: var(--#{$prefix}#{$color}-bg-subtle);
    color: var(--#{$prefix}#{$color}-text-emphasis);

    i {
      color: var(--#{$prefix}#{$color}-text-emphasis);
    }
  }
}
